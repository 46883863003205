<template>
  <div class="home">
    <v-app-bar color="indigo darken-1" dark clipped-left app class="no-print">
      <v-toolbar-title>{{ "休暇申請" }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- TMPアバターチップ -->
      <v-chip
        class="ma-2"
        color="pink darken-1"
        text-color="white"
        @click="userTop"
      >
        <template>
          <v-avatar left>
            <v-icon>mdi-keyboard-backspace</v-icon>
          </v-avatar>
          戻る
        </template>
      </v-chip>
      <v-chip
        class="ma-2"
        color="lime darken-4"
        text-color="white"
        @click="logout"
      >
        <template>
          <v-avatar left>
            <v-icon>mdi-account</v-icon>
          </v-avatar>
          {{ $store.state.name }}
        </template>
      </v-chip>
    </v-app-bar>

    <v-main>
      <v-container>
        <div>
          <div class="mb-1">
            <p class="text-h5">休暇申請一覧</p>
            <div class="d-flex">
              <DatePicker
                v-model="dateFrom"
                label="休暇取得日From"
                prepend-inner-icon="mdi-calendar"
                type="day"
                outlined
              />
            </div>
            <div class="d-flex">
              <DatePicker
                v-model="dateTo"
                label="休暇取得日To"
                prepend-inner-icon="mdi-calendar"
                type="day"
                outlined
              />
            </div>
            <div class="d-flex">
              <Btn
                color="info"
                icon="mdi-table-search"
                class="ml-4"
                @click="getData"
                >検索</Btn
              >
              <v-spacer></v-spacer>
              <Btn
                color="error"
                icon="mdi-calendar-plus"
                class="ml-4"
                @click="onAdd(0)"
                >新規申請</Btn
              >
            </div>
          </div>
          <hr />
          <div class="mt-5">
            <vue-good-table
              v-if="rows.length"
              :columns="cols"
              :rows="rows"
              :sort-options="{
                enabled: false
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 30,
                perPageDropdown: [5, 10, 30],
                dropdownAllowAll: true,
                setCurrentPage: 1,
                nextLabel: '前',
                prevLabel: '次',
                rowsPerPageLabel: '1ページ表示件数',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All'
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'remarks'">
                  <v-chip
                    class="mx-1"
                    small
                    v-for="remark in props.row.remarks"
                    :key="remark"
                  >
                    {{ remark }}
                  </v-chip>
                </span>
                <!-- Actions -->
                <span>
                  <Btn
                    color="info"
                    text
                    small
                    @click="onEdit(props.row.recordId)"
                    >照会</Btn
                  >
                </span>
              </template>
            </vue-good-table>
            <p v-else>該当する休暇申請が存在しません</p>
          </div>
        </div>
      </v-container>
    </v-main>
  </div>
</template>
<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";

// Components
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

// Views
import VacationRequestInput from "@/views/dialogs/VacationRequestInput.vue";

const DATE = new Date();
const DATEFROM =
  Number(DATE.getDate()) > 20
    ? DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
      "-" +
      "21".slice(-2)
    : Number(DATE.getMonth()) === 0
    ? DATE.getFullYear() + "-" + "12".slice(-2) + "-" + "21".slice(-2)
    : DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth())).slice(-2) +
      "-" +
      "21".slice(-2);
const DATETO =
  Number(DATE.getDate()) <= 20
    ? DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
      "-" +
      "20".slice(-2)
    : Number(DATE.getMonth()) === 11
    ? DATE.getFullYear() + "-" + "01".slice(-2) + "-" + "20".slice(-2)
    : DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 2)).slice(-2) +
      "-" +
      "20".slice(-2);
export default {
  name: "UserVacationRequest",
  mixins: [Common, Api, ShowDialogs],
  components: {
    VueGoodTable
  },
  computed: {
    Year() {
      return !this.date ? undefined : parseInt(this.date.split("-")[0]);
    },
    Month() {
      return !this.date ? undefined : parseInt(this.date.split("-")[1]);
    },
    Day() {
      if (!this.date) return undefined;
      const split = this.date.split("-");

      return split.length < 2 ? undefined : parseInt(split[2]);
    }
  },
  data() {
    return {
      date:
        DATE.getFullYear() +
        "-" +
        ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + Number(DATE.getDate())).slice(-2),
      show: false,
      cols: [],
      rows: [],
      dateFrom: DATEFROM,
      dateTo: DATETO,
      title: "休暇申請・参照",
      userId: 0
    };
  },
  methods: {
    /**
     * 追加
     */
    async onAdd() {
      console.log("add");
      const item = {
        user: this.userId
      };
      const result = await this.$showDialog(VacationRequestInput, {
        args: item
      });
    },
    /**
     * 編集
     * @param value index
     */
    onEdit(value) {
      console.log("Edit");
    },
    async getTargetMonth() {
      const startmonth = new Date(this.Year, this.Month, 21);
      const targetdt = new Date(this.Year, this.Month, this.Day);

      if (targetdt == startmonth) {
        targetdt.setMonth(targetdt.getMonth() + 1);
      } else if (targetdt > startmonth && targetdt.getDate() < 21) {
        targetdt.setMonth(targetdt.getMonth() - 1);
      } else if (targetdt < startmonth && targetdt.getDate() < 21) {
        targetdt.setMonth(targetdt.getMonth() - 1);
      }

      const targetMonth =
        targetdt.getFullYear() + "-" + ("00" + targetdt.getMonth()).slice(-2);

      return targetMonth;
    },
    async getData() {
      console.log("getData", this.$store.state.userincode);
      try {
        const targetMonth = await this.getTargetMonth();
        const param = {
          employeeId: this.$store.state.userincode,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo
        };
        const encode = encodeURI(JSON.stringify(param));
        const result = await this.$get(
          this.Paths.vacationrequest,
          "query=" + encode
        );

        this.cols = result.headers.map(header => ({
          label: header.text,
          field: header.value
        }));

        this.rows = result.body;
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    userTop() {
      console.log("userTop");
      this.$router.push({ name: "UserTop" });
    },
    async logout() {
      console.log(this.$store);
      if (await this.$confirm("ログアウトしますか？")) {
        this.$store.commit("logout");
        console.log(this.$store);
        this.$router.push("/login");
      }
    }
  },
  async created() {
    console.log("created", this.$route.params);
    this.params = this.$route.params;
    this.date = this.$getsecurityehimeServiceDate();
    this.userId = this.params.items;
    console.log("items", this.params.items);
    await this.getItems();
    await this.getData();
    try {
      await this.getData();
    } catch (e) {
      console.log(e);
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>
<style>
.vgt-wrap__footer .footer__row-count__label,
.vgt-wrap__footer .footer__navigation,
.vgt-wrap__footer .footer__row-count__select,
.vgt-wrap__footer .footer__navigation__page-btn span {
  font-size: 14px;
}
.range-text {
  text-align: center;
  line-height: 50px;
  height: 50px;
}
</style>
