<template>
  <DialogBase
    visible
    ref="dialog"
    title="休暇申請・参照"
    icon="mdi-calendar-edit-outline"
    fullscreen
    midium
    complete
    headerButtonDisp
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form ref="form">
      <div class="d-flex">
        <v-autocomplete
          v-model="user"
          readonly
          :items="users"
          outlined
          label="社員"
          item-text="name"
          item-value="code"
          prepend-icon="mdi-account"
          return-object
          max-width="300"
        >
        </v-autocomplete>
      </div>
      <div class="d-flex">
        <DatePicker
          v-model="date"
          label="申請日"
          prepend-icon="mdi-calendar"
          disabled
          type="day"
          outlined
        />
      </div>
      <div class="d-flex">
        <v-autocomplete
          v-model="shozoku"
          readonly
          :items="shozokus"
          label="所属"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
          max-width="300"
        >
        </v-autocomplete>
      </div>
      <div class="d-flex">
        <v-radio-group
          prepend-icon="mdi-radiobox-marked"
          v-model="select"
          row
          label="取得パターン:"
          @change="getData(false)"
        >
          <v-radio
            label="1日"
            prepend-icon="mdi-toggle-switch"
            :value="Selects.Oneday"
          ></v-radio>
          <v-radio
            label="複数日"
            prepend-icon="mdi-toggle-switch"
            :value="Selects.FromTo"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="d-flex">
        <DatePicker
          v-model="dateOne"
          label="休暇取得日"
          v-if="select == Selects.Oneday"
          prepend-icon="mdi-calendar"
          type="day"
          outlined
        />
      </div>
      <div class="d-flex">
        <DatePicker
          v-model="dateFrom"
          label="休暇取得日From"
          v-if="select == Selects.FromTo"
          prepend-icon="mdi-calendar"
          type="day"
          outlined
        />
      </div>
      <div class="d-flex">
        <DatePicker
          v-model="dateTo"
          label="休暇取得日To"
          v-if="select == Selects.FromTo"
          prepend-icon="mdi-calendar"
          type="day"
          outlined
        />
      </div>
      <div class="d-flex">
        <v-radio-group
          v-model="VacationKbn"
          row
          label="休暇区分:"
          prepend-icon="mdi-radiobox-marked"
        >
          <v-radio
            label="有給"
            prepend-icon="mdi-toggle-switch"
            :value="VacationKbns.paid"
          ></v-radio>
          <v-radio
            label="欠勤"
            prepend-icon="mdi-toggle-switch"
            :value="VacationKbns.absent"
          ></v-radio>
          <v-radio
            label="その他"
            prepend-icon="mdi-toggle-switch"
            :value="VacationKbns.other"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="d-flex">
        <v-radio-group
          v-model="Reason"
          row
          label="事由:"
          prepend-icon="mdi-radiobox-marked"
        >
          <v-radio
            label="私用"
            prepend-icon="mdi-toggle-switch"
            :value="Reasons.mine"
          ></v-radio>
          <v-radio
            label="慶弔"
            prepend-icon="mdi-toggle-switch"
            :value="Reasons.keityo"
          ></v-radio>
          <!-- <v-radio
                label="その他"
                prepend-icon="mdi-toggle-switch"
                :value="Reasons.other"
            ></v-radio> -->
        </v-radio-group>
      </div>
      <div class="d-flex">
        <v-text-field
          v-model="reasontext"
          label="理由"
          prepend-icon="mdi-wallet"
          outlined
          :rules="[
            value => !!value || Reason !== '2' || '理由を入力してください'
          ]"
        ></v-text-field>
      </div>
      <div class="d-flex flex-row-reverse mt-5">
        <!-- <v-btn type="login" color="success" @click="complete(true)">
          登録
        </v-btn> -->
      </div>
    </v-form>
  </DialogBase>
</template>
<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import AttendanceFix from "@/models/AttendanceFix";
import { COMMON_SHIFT_PATTERNS_REF as CommonShiftRefCode } from "@/defines";
import moment from "moment";
import VacationRequest from "@/models/VacationRequest";

const Shozokus = [{ name: "", code: "" }];
const Shifts = [];

const Selects = {
  Oneday: 0,
  FromTo: 1
};

const VacationKbns = {
  paid: 1,
  absent: 2,
  other: 3
};

const Reasons = {
  mine: 1,
  keityo: 2,
  other: 3
};

export default {
  name: "VacationRequestInput",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  props: {
    kkubun: {
      type: Number,
      default: 1,
      require: true
    }
  },
  computed: {
    Title() {
      const args = this.args;
      if (args) return "勤務実績追加・修正";
      const date = new Date(args.date);
      return args.name
        ? args.name
        : `${date.getMonth() + 1} 月 ${date.getDate()} 日`; //args.date;
    }
  },
  data() {
    return {
      date: "",
      data: {},
      shozokus: Shozokus,
      shozoku: {},
      shifts: Shifts,
      shift: {},
      toggle: [],
      edit: true,
      arrayEdit: false,
      recordShifts: {},
      select: Selects.Oneday,
      Selects,
      VacationKbn: VacationKbns.paid,
      VacationKbns,
      Reason: Reasons.mine,
      Reasons,
      forPaid: "",
      users: []
    };
  },
  methods: {
    shozokuSelect() {
      console.log("selected", this.shozoku);
      const shozoku = this.shozoku;
      const filtershifts = this.recordShifts.filter(
        e => e.refcode === shozoku || e.refcode === CommonShiftRefCode
      );
      this.shifts = filtershifts;
    },
    /*shiftSelect() {
        console.log("shiftSelect", this.shift, this.recordShifts);
        const filter = this.recordShifts.filter(
          e => e.code === String(this.shift)
        );
        if (filter.length > 0) {
          const set = filter[0];
          console.log(set);
          if (!this.edit) {
            this.data.start = set.option1;
            this.data.end = set.option2;
            this.data.breaktime = set.option3;
          }
          if (set.refcode === "99999") {
            this.forPaid = set.code;
          } else {
            this.forPaid = "";
          }
        }
      },*/
    close(result) {
      this.resolve(result);
      this.$close();
    },
    setdata() {
      console.log("setdata");
      if (!this.$refs.form.validate()) return;
      const userincode = this.user.code ? this.user.code : this.user;
      const selectUser = this.users.filter(e => e.code === userincode);
      const shozokuincode = this.shozoku.code
        ? this.shozoku.code
        : this.shozoku;
      const selectShozoku = this.shozokus.filter(e => e.code === shozokuincode);
    },
    async complete() {
      console.log("complete");

      if (!this.$refs.form.validate()) return;
      // this.$loading();
      const data = { ...this.data };
      // const args = this.args;
      // Object.keys(args).forEach(key => {
      //   if (this.data[key]) args[key] = data[key];
      // });

      // data.date = new Date(this.date).getTime();
      // if (this.$isEmpty(data.recordid)) {
      //   data.datestr = this.date;
      //   data.affiliation = this.shozoku;
      // }
      // data.shiftpatternid = this.shift ? this.shift : "";
      // if (this.shift !== null && !this.$isEmpty(this.shift.code)) {
      //   data.shiftpatternid = this.shift.code ? this.shift.code : "";
      // } else {
      //   data.shiftid = "";
      // }
      // if (data.shiftpatternid === "") {
      //   this.forPaid = "";
      // }
      // const setshiftid = [data.shiftid];

      // 社員情報
      const empl = [""];
      console.log("employeeId", this.user);
      console.log("employeeId.code", this.user.code);
      if (this.user.code === undefined) {
        empl[0] = this.user;
      } else {
        empl[0] = this.user.code;
      }

      // 所属情報
      const szk = [""];
      console.log("shozoku", this.shozoku);
      console.log("shozoku.code", this.shozoku.code);
      // console.log("shiftpatternid", this.shift);
      if (this.shozoku.code == undefined) {
        szk[0] = this.shozoku;
      } else {
        szk[0] = this.shozoku.code;
      }
      // if (data.affiliation != szk[0]) {
      //   setshiftid[0] = "";
      // }
      // console.log("forPaid", this.forPaid);

      const payLoad = new VacationRequest({
        recordId: 0,
        requestDate: this.date,
        employeeId: empl[0],
        affiliationId: szk[0],
        vacationKbn: this.vacationKbn,
        resonkb: this.Reason,
        resonText: this.reasontext,
        dateFrom: this.select === Selects.Oneday ? this.dateOne : this.dateFrom,
        dateTo: this.select === Selects.Oneday ? this.dateOne : this.dateTo
      });

      try {
        let result;
        if (this.$isEmpty(data.recordid)) {
          result = await this.$post(this.Paths.vacationrequestFix, payLoad);
          if (result) {
            await this.$info("申請しました。", "休暇申請");
            console.log("complete end");
            this.$close(true);
          }
        } else {
          result = await this.$put(this.Paths.vacationrequestFix, payLoad);
          if (result) {
            await this.$info("更新しました。", "休暇申請");
            console.log("complete end");
            this.$close(true);
          }
        }
      } catch (e) {
        console.log("complete error", e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async getUsers(userincode) {
      console.log("getUsers");
      try {
        const result = await this.$get(this.Paths.shainPulldown);

        this.selectShozoku = this.shozokus.filter(e => e.code === this.shozoku);
        if (result) {
          this.users.length = 0;
          this.users = result;
          this.user = userincode ? userincode : result[0].code;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getShozoku(shozokuincode) {
      console.log("shozoku");
      try {
        const result = await this.$get(this.Paths.shozokuFilter);
        if (result) {
          this.shozokus.length = 0;
          this.shozokus = result;
          this.shozoku = shozokuincode ? shozokuincode : result[0].code;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getShiftPattern() {
      console.log("shift-pattern");

      try {
        const result = await this.$get(this.Paths.shiftpatternPulldown);
        if (result) {
          this.shifts.length = 0;
          this.recordShifts.length = 0;
          this.recordShifts = result;

          if (this.edit && this.data.affiliation) {
            this.shifts = result.filter(
              e =>
                e.refcode === this.data.affiliation ||
                e.refcode === CommonShiftRefCode
            );

            if (this.data.shiftpatternid) {
              let idx = 0;
              let counter = 0;
              this.shifts.forEach(f => {
                if (f.code === this.data.shiftpatternid) idx = counter;
                counter += 1;
              });
              this.shift = this.shifts[idx];
            }
          }
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    setRadioValues() {
      // 出勤種別
      if (this.data.workclassificationid === "1") {
        this.selectWork = this.SelectsWork.Break;
      } else {
        this.selectWork = this.SelectsWork.Default;
      }

      if (this.data.workclassificationid) {
        if (parseInt(this.data.workclassificationid) > 90000) {
          this.forPaid = this.data.workclassificationid;
        } else {
          this.forPaid = "";
        }
      }

      // 遅刻早退
      switch (this.data.lateleave) {
        case "遅":
          this.selectLateLeave = this.SelectsLateLeave.Late;
          break;
        case "早":
          this.selectLateLeave = this.SelectsLateLeave.Leave;
          break;
        case "遅早":
          this.selectLateLeave = this.SelectsLateLeave.LateLeave;
          break;
        default:
          this.selectLateLeave = this.SelectsLateLeave.Default;
          break;
      }

      // 出勤日数
      if (this.data.reserveid1 === "2") {
        this.selectDayCount = this.SelectsDayCount.Over;
      } else {
        this.selectDayCount = this.SelectsDayCount.Default;
      }

      // 夜勤日数
      if (this.data.nightshift === "1.0") {
        this.selectNightCount = this.SelectsNightCount.Over;
      } else {
        this.selectNightCount = this.SelectsNightCount.Default;
      }

      // 応援・その他
      if (this.data.assistFlag === "1") {
        this.selectsOption = this.SelectsOption.ASSIST;
      } else if (this.data.otherFlag === "1") {
        this.selectsOption = this.SelectsOption.OTHER;
      } else {
        this.selectsOption = this.SelectsOption.NONE;
      }

      // 欠勤
      if (this.data.reserveid2 === "1") {
        this.selectAbsence = this.SelectsAbsence.Absence;
      } else {
        this.selectAbsence = this.SelectsAbsence.Default;
      }

      console.log(this.data);
    }
  },
  async created() {
    console.log("created", this.args);
    const args = this.args;
    this.user = args.user;
    await this.getUsers(this.user);
    await this.getShozoku();

    this.setdata();
    if (!this.$isEmpty(args)) {
      if (this.$isEmpty(args["recordid"])) {
        this.edit = false;
      } else {
        this.edit = true;
      }

      if (!Array.isArray(args)) {
        this.data = { ...args };
      } else {
        this.arrayEdit = true;
        const data = {};
        args.forEach(select => {
          Object.keys(select).forEach(key => {
            if (!data[key]) data[key] = select[key];
            else if (data[key] !== select[key]) data[key] = null;
          });
        });
        this.data = data;
      }
      if (
        this.data.assistFlag !== "1" &&
        this.data.otherFlag !== "1" &&
        this.kkubun !== 4
      ) {
        this.data.amount = 0;
      }

      this.date = moment(this.data.datestr).format("YYYY-MM-DD");

      this.setRadioValues();

      //await this.getShozoku();
      await this.getShiftPattern();
    }
  }
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

table th {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  width: 5%;
  padding: 10px 5px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

table td {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-top: none;
  width: 5%;
  padding: 10px 5px;
  font-size: 0.875rem;
}

::v-deep .editable-control input[readonly="readonly"] {
  background-color: lightgray;
  cursor: not-allowed;
}
</style>
