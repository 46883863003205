/**
 * 管理者勤務表画面からの出退勤実績登録・更新用
 */
export default class VacationRequest {
  recordId: string;
  requestDate: Date;
  employeeId: string;
  employeeNm: string;
  affiliationId: string;
  affiliationNm: string;
  vacationKbn: string;
  resonKbn: string;
  resonText: string;
  approvalUser1: string;
  approvalFlag1: string;
  approcalDate1: Date;
  approvalUser2: string;
  approvalFlag2: string;
  approcalDate2: Date;
  approvalUser3: string;
  approvalFlag3: string;
  approcalDate3: Date;
  approvalUser4: string;
  approvalFlag4: string;
  approcalDate4: Date;
  approvalUser5: string;
  approvalFlag5: string;
  approcalDate5: Date;
  approvalUser6: string;
  approvalFlag6: string;
  approcalDate6: Date;
  approvalUser7: string;
  approvalFlag7: string;
  approcalDate7: Date;
  approvalUser8: string;
  approvalFlag8: string;
  approcalDate8: Date;
  approvalUser9: string;
  approvalFlag9: string;
  approcalDate9: Date;
  approvalUser10: string;
  approvalFlag10: string;
  approvalDate10: Date;
  dateFrom: Date;
  dateTo: Date;

  constructor(props: {
    recordId: string;
    requestDate: Date;
    employeeId: string;
    employeeNm: string;
    affiliationId: string;
    affiliationNm: string;
    vacationKbn: string;
    resonKbn: string;
    resonText: string;
    approvalUser1: string;
    approvalFlag1: string;
    approcalDate1: Date;
    approvalUser2: string;
    approvalFlag2: string;
    approcalDate2: Date;
    approvalUser3: string;
    approvalFlag3: string;
    approcalDate3: Date;
    approvalUser4: string;
    approvalFlag4: string;
    approcalDate4: Date;
    approvalUser5: string;
    approvalFlag5: string;
    approcalDate5: Date;
    approvalUser6: string;
    approvalFlag6: string;
    approcalDate6: Date;
    approvalUser7: string;
    approvalFlag7: string;
    approcalDate7: Date;
    approvalUser8: string;
    approvalFlag8: string;
    approcalDate8: Date;
    approvalUser9: string;
    approvalFlag9: string;
    approcalDate9: Date;
    approvalUser10: string;
    approvalFlag10: string;
    approvalDate10: Date;
    dateFrom: Date;
    dateTo: Date;
  }) {
    this.recordId = props.recordId;
    this.requestDate = props.requestDate;
    this.employeeId = props.employeeId;
    this.employeeNm = props.employeeNm;
    this.affiliationId = props.affiliationId;
    this.affiliationNm = props.affiliationNm;
    this.vacationKbn = props.vacationKbn;
    this.resonKbn = props.resonKbn;
    this.resonText = props.resonText;
    this.approvalUser1 = props.approvalUser1;
    this.approvalFlag1 = props.approvalFlag1;
    this.approcalDate1 = props.approcalDate1;
    this.approvalUser2 = props.approvalUser2;
    this.approvalFlag2 = props.approvalFlag2;
    this.approcalDate2 = props.approcalDate2;
    this.approvalUser3 = props.approvalUser3;
    this.approvalFlag3 = props.approvalFlag3;
    this.approcalDate3 = props.approcalDate3;
    this.approvalUser4 = props.approvalUser4;
    this.approvalFlag4 = props.approvalFlag4;
    this.approcalDate4 = props.approcalDate4;
    this.approvalUser5 = props.approvalUser5;
    this.approvalFlag5 = props.approvalFlag5;
    this.approcalDate5 = props.approcalDate5;
    this.approvalUser6 = props.approvalUser6;
    this.approvalFlag6 = props.approvalFlag6;
    this.approcalDate6 = props.approcalDate6;
    this.approvalUser7 = props.approvalUser7;
    this.approvalFlag7 = props.approvalFlag7;
    this.approcalDate7 = props.approcalDate7;
    this.approvalUser8 = props.approvalUser8;
    this.approvalFlag8 = props.approvalFlag8;
    this.approcalDate8 = props.approcalDate8;
    this.approvalUser9 = props.approvalUser9;
    this.approvalFlag9 = props.approvalFlag9;
    this.approcalDate9 = props.approcalDate9;
    this.approvalUser10 = props.approvalUser10;
    this.approvalFlag10 = props.approvalFlag10;
    this.approvalDate10 = props.approvalDate10;
    this.dateFrom = props.dateFrom;
    this.dateTo = props.dateTo;
  }
}
